import { Category } from '@/interfaces/category.interface';
import { File } from '@/interfaces/file.interface';
import { CategoryState } from '@/interfaces/states/category-state.interface';
import alphaSort from '@/utils/alphabetic-sort';

export default {
  items: (state: CategoryState): Category[] => state.items,

  loading: (state: CategoryState): boolean => state.loading,

  rootCategories: (state: CategoryState): Category[] => {
    const { items } = state;
    return items
      .filter((item: Category) => item.parentIds.length === 0)
      .sort(({ title }, { title: title2 }) => alphaSort(title, title2));
  },

  subCategories: (state: CategoryState): ((id: string) => Category[]) => {
    const { items } = state;
    return (id: string): Category[] =>
      // eslint-disable-next-line implicit-arrow-linebreak
      items
        .filter((item) => item.parentIds.includes(id))
        .sort(({ title }, { title: title2 }) => alphaSort(title, title2));
  },

  categoryFiles: (state: CategoryState): File[] => {
    const { categoryFiles } = state;
    return categoryFiles.sort(({ title }, { title: title2 }) => alphaSort(title, title2));
  },

  level: (state: CategoryState): number => state.level,
};
