import { ResponseError } from '@/classes/response-error';
import { ForgotRequest } from '@/dtos/forgot-request.interface';
import { LoginRequest } from '@/dtos/login-request.interface';
import { LoginResponse } from '@/dtos/login-response.interface';
import i18n from '@/i18n';
import { AuthState } from '@/interfaces/states/auth-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { NotificationActions } from '@/store/notification/actions.enum';
import getResJson from '@/utils/get-res-json';
import { ActionContext } from 'vuex';
import { AuthActions } from './actions.enum';

export default {
  async [AuthActions.LOGIN](
    { commit, dispatch }: ActionContext<AuthState, RootState>,
    { username, password }: LoginRequest,
  ): Promise<void> {
    const url = `${process.env.VUE_APP_API_URL}/auth/loginv2`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Basic ${btoa(unescape(encodeURIComponent(`${username}:${password}`)))}`,
          'X-Uid': '1234',
        },
      });

      const data = await getResJson<LoginResponse>(response);

      commit(AuthActions.LOGIN, { ...data, username, password });
    } catch (error) {
      const code = error?.response?.status;
      let i18nKey = '';

      switch (code) {
        case 401:
          i18nKey = 'login.errCredentialsWrong';
          break;
        case 500:
          i18nKey = 'login.errServerNotReachable';
          break;
        default:
          i18nKey = '';
          console.error(error);
      }

      commit(AuthActions.SET_ERROR, error);
      dispatch(`notification/${NotificationActions.SET_ERROR}`, i18n.t(i18nKey).toString(), {
        root: true,
      });
      router.replace('/');

      throw error;
    }
  },

  [AuthActions.LOGOUT]({ commit }: ActionContext<AuthState, RootState>): void {
    commit(AuthActions.LOGOUT);
  },

  async [AuthActions.FORGOT](
    { commit, dispatch }: ActionContext<AuthState, RootState>,
    { username }: ForgotRequest,
  ): Promise<void> {
    const url = `${process.env.VUE_APP_LEGACY_API_URL}/auth/forgot`;

    const formData = new FormData();
    formData.append('username', username);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'X-Uid': '1234',
          'X-App-Secret': `${process.env.VUE_APP_LEGACY_AUTH_SECRET}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new ResponseError(response);
      }

      dispatch(
        `notification/${NotificationActions.SET_SUCCESS}`,
        i18n.t('forgot.msgSuccess').toString(),
        {
          root: true,
        },
      );
    } catch (error) {
      const code = error?.response?.status;
      let i18nKey = '';

      switch (code) {
        case 401:
          i18nKey = 'forgot.errRequestWrong';
          break;
        case 500:
          i18nKey = 'forgot.errServerNotReachable';
          break;
        default:
          i18nKey = '';
          console.error(error);
      }

      commit(AuthActions.SET_ERROR, error);
      dispatch(`notification/${NotificationActions.SET_ERROR}`, i18n.t(i18nKey).toString(), {
        root: true,
      });
      router.replace('/');

      throw error;
    }
  },
};
