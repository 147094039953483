import { FileState } from '@/interfaces/states/file-state.interface';

const state: FileState = {
  items: [],
  showHighlights: true,
  loading: true,
  error: {
    code: null,
    msg: null,
  },
};

export default state;
