// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import Login from '@/views/Login.vue';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/highlights',
    name: 'Highlight',
    component: () => import('@/views/Highlights.vue'),
  },
  {
    path: '/categories/:id?',
    name: 'Category',
    props: true,
    component: () => import('@/views/Categories.vue'),
  },
  {
    path: '/favorites',
    name: 'Favorit',
    component: () => import('@/views/Favorites.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.path === '/' || store.state.auth.token) {
    return next();
  }

  return next('/');
});

export default router;
