import { SearchResponse } from '@/dtos/search-response.interface';
import i18n from '@/i18n';
import { SetStateError } from '@/interfaces/set-state-error.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import { SearchState } from '@/interfaces/states/search-state.interface';
import { NotificationActions } from '@/store/notification/actions.enum';
import fetchWithToken from '@/utils/fetch-with-token';
import getResJson from '@/utils/get-res-json';
import mapThumbnailToFiles from '@/utils/map-thumbnail-files';
import apiFileToFile from '@/utils/transform/api-file-to-file';
import apiThumbnailToThumbnail from '@/utils/transform/api-thumbnail-to-thumbnail';
import { ActionContext } from 'vuex';
import { SearchActions } from './actions.enum';

export default {
  async [SearchActions.SEARCH](
    { commit, dispatch, rootState }: ActionContext<SearchState, RootState>,
    keyword: string,
  ): Promise<void> {
    const url = `${process.env.VUE_APP_API_URL}/search/${
      rootState.locale.currentId
    }?keyword=${encodeURIComponent(keyword)}`;

    try {
      await dispatch(SearchActions.SET_LOADING, true);
      const response = await fetchWithToken(rootState, url, dispatch);

      const data = await getResJson<SearchResponse>(response);

      const items = mapThumbnailToFiles(
        apiFileToFile(data.items),
        apiThumbnailToThumbnail(data.thumbnails),
      );

      commit(SearchActions.SEARCH, { keyword, items });
      await dispatch(SearchActions.SET_LOADING, false);
    } catch (error) {
      dispatch(SearchActions.SET_ERROR, { error, i18nKey: 'search.errSearch' });

      throw error;
    }
  },

  async [SearchActions.RESET]({ commit }: ActionContext<SearchState, RootState>): Promise<void> {
    commit(SearchActions.RESET);
  },

  [SearchActions.SET_LOADING](
    { commit }: ActionContext<SearchState, RootState>,
    loading: boolean,
  ): void {
    commit(SearchActions.SET_LOADING, loading);
  },

  [SearchActions.SET_ERROR](
    { commit, dispatch }: ActionContext<SearchState, RootState>,
    { error, i18nKey }: SetStateError,
  ): void {
    commit(SearchActions.SET_ERROR, error);
    dispatch(`notification/${NotificationActions.SET_ERROR}`, i18n.t(i18nKey).toString(), {
      root: true,
    });
  },
};
