import { File } from '@/interfaces/file.interface';
import { FileState } from '@/interfaces/states/file-state.interface';
import alphaSort from '@/utils/alphabetic-sort';

export default {
  items: (state: FileState): File[] => {
    const { items } = state;
    return items.sort(({ title }, { title: title2 }) => alphaSort(title, title2));
  },
  loading: (state: FileState): boolean => state.loading,
  showHighlights: (state: FileState): boolean => state.showHighlights,
};
