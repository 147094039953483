
























































import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import SubmitButton from '@/components/BaseSubmitButton.vue';
import InputField from '@/components/BaseInputField.vue';
import ForgotPasswordDialog from '@/components/ForgotPasswordDialog.vue';
import { AuthActions } from '@/store/auth/actions.enum';
import { LocaleActions } from '@/store/locale/actions.enum';

export default Vue.extend({
  name: 'Login',

  components: {
    ValidationObserver,
    SubmitButton,
    InputField,
    ForgotPasswordDialog,
  },

  data() {
    return {
      username: '',
      password: '',
      loading: false,
      forgotPasswordVisible: false,
    };
  },

  computed: {
    ...mapGetters('auth', {
      initialUsername: 'username',
      initialPassword: 'password',
    }),
    ...mapGetters('locale', { locales: 'items', localeId: 'currentId' }),
    ...mapGetters('file', ['showHighlights']),

    logoPath() {
      return `${process.env.BASE_URL}img/logos/login.svg`;
    },

    isIE11() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return !!window.MSInputMethodContext && !!(document as any).documentMode;
    },
  },

  created() {
    this.username = this.initialUsername;
    this.password = this.initialPassword;
  },

  methods: {
    ...mapActions('auth', { authWithCredentials: AuthActions.LOGIN }),
    ...mapActions('locale', {
      fetchLocale: LocaleActions.FETCH,
      setCurrentLocale: LocaleActions.SET_CURRENT,
    }),

    async login() {
      this.loading = true;

      await this.authWithCredentials({
        username: this.username,
        password: this.password,
      });
      await this.fetchLocale();
      await this.setCurrentLocale(this.localeId ?? this.locales?.[0]?.id);

      this.$router.push(this.showHighlights ? '/highlights' : '/categories');

      this.loading = false;
    },

    showForgotPassword() {
      this.forgotPasswordVisible = true;
    },
  },
});
