import { HighlightListResponse } from '@/dtos/highlight-list-response.interface';
import i18n from '@/i18n';
import { SetStateError } from '@/interfaces/set-state-error.interface';
import { FileState } from '@/interfaces/states/file-state.interface';
import { RootState } from '@/interfaces/states/root-state.interface';
import { NotificationActions } from '@/store/notification/actions.enum';
import fetchWithToken from '@/utils/fetch-with-token';
import getResJson from '@/utils/get-res-json';
import mapThumbnailToFiles from '@/utils/map-thumbnail-files';
import apiFileToFile from '@/utils/transform/api-file-to-file';
import apiThumbnailToThumbnail from '@/utils/transform/api-thumbnail-to-thumbnail';
import { ActionContext } from 'vuex';
import { FileActions } from './actions.enum';

export default {
  async [FileActions.FETCH_HIGHLIGHTS]({
    commit,
    dispatch,
    rootState,
  }: ActionContext<FileState, RootState>): Promise<void> {
    const url = `${process.env.VUE_APP_API_URL}/highlight/list/${rootState.locale.currentId}`;

    try {
      const fetchHighlights = async () => {
        const response = await fetchWithToken(rootState, url, dispatch);

        const data = await getResJson<HighlightListResponse>(response);

        const items = mapThumbnailToFiles(
          apiFileToFile(data.items),
          apiThumbnailToThumbnail(data.thumbnails),
        );

        commit(FileActions.FETCH_HIGHLIGHTS, items);
      };

      await dispatch(FileActions.SET_LOADING, true);
      await fetchHighlights();
      await dispatch(FileActions.SET_LOADING, false);

      // to fetch current list due to stale workbox strategy
      setTimeout(() => fetchHighlights(), 500);
    } catch (error) {
      dispatch(FileActions.SET_ERROR, { error, i18nKey: 'file.errFetchHighlights' });

      throw error;
    } finally {
      await dispatch(FileActions.SET_LOADING, false);
    }
  },

  [FileActions.SET_LOADING](
    { commit }: ActionContext<FileState, RootState>,
    loading: boolean,
  ): void {
    commit(FileActions.SET_LOADING, loading);
  },

  [FileActions.SET_ERROR](
    { commit, dispatch }: ActionContext<FileState, RootState>,
    { error, i18nKey }: SetStateError,
  ): void {
    commit(FileActions.SET_ERROR, error);
    dispatch(`notification/${NotificationActions.SET_ERROR}`, i18n.t(i18nKey).toString(), {
      root: true,
    });
  },
};
